import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          id="B"
          transform="translate(-10.000000, -1.000000)"
          d="M 22.00,34.00
           C 22.00,34.00 46.00,34.00 46.00,34.00
             46.00,34.00 52.22,34.17 52.22,34.17
             54.45,38.94 60.22,53.43 60.22,53.43
             60.22,53.43 69.13,34.96 69.13,34.96
             69.13,34.96 81.48,34.22 81.48,34.22
             81.48,34.22 77.13,36.48 77.13,36.48
             77.13,36.48 78.00,54.00 78.00,54.00
             78.00,54.00 77.52,62.78 77.52,62.78
             77.52,62.78 82.00,65.00 82.00,65.00
             82.00,65.00 64.61,65.22 64.61,65.22
             66.20,64.20 69.28,64.07 69.91,62.17
             71.57,58.85 70.13,42.86 69.30,39.26
             69.30,39.26 59.00,65.00 59.00,65.00
             59.00,65.00 57.00,65.00 57.00,65.00
             57.00,65.00 46.26,39.61 46.26,39.61
             46.26,39.61 46.09,62.04 46.09,62.04
             46.09,62.04 51.00,65.00 51.00,65.00
             51.00,65.00 39.00,64.00 39.00,64.00
             40.66,63.03 42.07,64.43 43.13,62.65
             44.68,60.06 44.06,47.49 44.00,44.00
             43.95,41.79 45.07,37.74 43.45,36.00
             41.46,33.84 37.56,35.16 34.96,35.35
             34.96,40.93 35.90,57.70 33.55,61.89
             30.35,67.57 18.71,67.67 16.66,61.00
             15.20,56.28 19.81,56.11 23.00,56.00
             23.00,56.00 22.39,61.91 22.39,61.91
             22.65,62.93 25.13,65.06 26.26,63.35
             27.94,60.81 27.76,39.48 26.61,36.52
             25.76,34.36 23.66,35.52 22.00,34.00 Z
           M 68.96,40.96M 90.48,35.00"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
